import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import async from 'async';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
// import Button from '@material-ui/core/Button';
import { Prompt } from 'react-router-dom';
import IntlMessages from 'utils/IntlMessages';
import { injectIntl } from 'react-intl';
import CategoryService from 'services/Category';
import SettingsActions from 'store/reducers/Settings';
import Util from 'utils/Util';
import { TextField } from 'components/custom/FormElements';
import ProductionService from 'services/Production';
import PriceService from 'services/Price';
import ServiceService from 'services/Service';
import BundleService from 'services/Bundle';
import {
  composeValidators,
  greaterOrEqualThanMultipliedBy,
  greaterOrEqualThanValue,
  greaterOrEqualValue,
  lessOrEqualThan,
  lessOrEqualThanValue,
  required,
} from 'config/InputErrors';
import HoverTitle from 'components/custom_v2/HoverTitle';
import ConfirmationDialog from 'components/custom_v2/ConfirmationDialog';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import bundle from 'assets/images/mk/bundle.png';
import service from 'assets/images/mk/service.png';
import HeaderImage from 'assets/images/kotler/sectionHeaders/prices.jpeg';
import KotlerSectionHeader from 'components/custom/KoTlerSectionHeader';
import SectionInfoText from 'components/custom/SectionInfoText';
import KotlerTableTitle from 'components/custom/KotlerTableTitle';
import HeadConfig from 'components/custom/HeadConfig';
import ConfirmCancelButtons from 'components/custom/ConfirmCancelButtons';
import KotlerProductIcon from 'components/custom/KotlerProductIcon';
import { STATUS } from 'modules/sessions';

class Products extends React.Component {
  state = {
    categories: [],
    confirmSaveModal: false,
    confirmExitModal: false,
  };
  isEscapeKey = false;
  confirmLessFivePercentProduct = [];
  boolConfirmLessFivePercentProduct = false;
  fieldModified = false;
  canExit = false;
  goTo = '/';
  mkmode = {};
  componentDidMount() {
    const { toggleAlert, user } = this.props;
    if ( !user.activeSession ) {
      return toggleAlert( 'ERROR' );
    }

    this.activeSession = user.activeSession;
    this.license = _.find( user.licenses, { session: { id: user.activeSession.id } } );
    if ( !this.license || !this.license.workGroup ) {
      return toggleAlert( 'ERROR' );
    }
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields };
    }
    document.addEventListener( 'keydown', e => this.handleKeyPress( e ), true );
    this.getData();
  }

  componentWillUnmount() {
    this.fieldModified = false;
    document.removeEventListener( 'keydown', this.handleKeyPress );
  }

  toggleConfirmExitModal = () => {
    const { confirmExitModal } = this.state;
    this.setState( { confirmExitModal: !confirmExitModal } );
  };

  setConfirmExitModal = () => {
    const { history } = this.props;
    this.canExit = true;
    if ( this.goTo === '' ) {
      this.fieldModified = false;
    } else {
      history.push( this.goTo );
    }
  };

  setChangeStatus = () => {
    this.fieldModified = true;
  };

  handleKeyPress = ( event ) => {
    const { user } = this.props;
    if ( event.key === 'Enter' ) {
      if ( event.target.type === 'button' ) {
        event.target.click();
      } else {
        event.preventDefault();
        if (
          user
          && user.activeSession
          && user.activeSession.status !== STATUS.READY
        ) return;
        const { confirmExitModal } = this.state;
        if ( !confirmExitModal ) {
          this.setState( { confirmSaveModal: true } );
        }
      }
    }
    if ( event.key === 'Escape' ) {
      this.isEscapeKey = true;
    }
  };

  getData = () => {
    const { toggleAlert, toggleLoading } = this.props;

    toggleLoading( true );

    async.parallel(
      {
        categories: ( cb ) => {
          CategoryService.getCategories( { 'filters[session]': this.activeSession.id } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        products: ( cb ) => {
          PriceService.getProducts( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            'filters[active]': 1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        services: ( cb ) => {
          /* eslint-disable-next-line max-len */
          ServiceService.getServicesV2( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
        bundles: ( cb ) => {
          /* eslint-disable-next-line max-len */
          BundleService.getBundlesV2( this.activeSession.id, {
            workgroup: this.license.workGroup.id,
            limit: -1,
          } ).then( ( response ) => {
            if ( !response.ok ) return cb( response.errors );
            cb( null, response.data.data );
          } );
        },
      },
      ( error, results ) => {
        toggleLoading( false );
        if ( error ) toggleAlert( error );
        const { services } = results;
        const { bundles } = results;
        let categories = _.map( results.categories, category => ( {
          ...category,
          products: _.filter( results.products, { category: { id: category.id } } ),
        } ) );
        if ( !this.mkmode.decision.price.laptop ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Lapto' ) === -1,
          );
        }
        if ( !this.mkmode.decision.price.tablet ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Tablet' ) === -1,
          );
        }
        if ( !this.mkmode.decision.price.celular ) {
          categories = _.filter(
            categories,
            o => o.name.indexOf( 'Celular' ) === -1,
          );
        }
        this.setState( { categories, bundles, services } );
      },
    );
  };

  submitForm = ( formData, FormOptions ) => {
    const { dirty, dirtyFields } = FormOptions.getState();
    const { toggleAlert, toggleLoading, intl } = this.props;
    toggleLoading( true );
    async.parallel(
      [
        ( cb ) => {
          if ( formData && formData.categories.length !== 0 ) {
            /* eslint-disable-next-line max-len */
            if ( dirty && dirtyFields.categories ) this.sendDataCategories( formData.categories, cb );
            else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
        ( cb ) => {
          if ( formData && formData.services.length !== 0 ) {
            if ( dirty && dirtyFields.services ) this.sendDataServices( formData.services, cb );
            else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
        ( cb ) => {
          if ( formData && formData.bundles.length !== 0 ) {
            if ( dirty && dirtyFields.bundles ) this.sendDataBundles( formData.bundles, cb );
            else cb( null, 'NoChangesForSave' );
          } else cb( null, 'NoDataToSend' );
        },
      ],
      ( error, result ) => {
        let oneDataSaved = false;
        let noChangesCounter = 0;
        _.map( result, ( item ) => {
          switch ( item ) {
          case 'DataSaved':
            oneDataSaved = true;
            break;
          case 'NoChangesForSave':
            noChangesCounter += 1;
            break;
          case 'NoDataToSend':
            break;
          case 'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET':
            toggleAlert(
              `${intl.formatMessage( {
                id:
                    'ERROR_MESSAGE_THIS_WORKGROUP_DOES_NOT_HAVE_ENOUGH_MARKETING_BUDGET',
              } )}services`,
              'error',
            );
            break;
          default:
            toggleAlert( item, 'error' );
          }
        } );

        if ( oneDataSaved ) {
          this.getData();
          toggleAlert( 'dataSaved', 'info' );
          this.fieldModified = false;
        } else if ( noChangesCounter >= 3 ) {
          toggleAlert( 'NoChangesForSave', 'info' );
          this.fieldModified = false;
        }
        toggleLoading( false );
      },
    );
  };
  sendDataCategories = ( categories, cb ) => {
    const prices = [];
    _.map( categories, ( category ) => {
      _.map( category.products, ( product ) => {
        const data = {
          product: product.id,
          price: product.price,
          tradeSalePrice: product.tradeSalePrice,
        };
        prices.push( data );
      } );
    } );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      prices,
    };

    /* eslint-disable-next-line max-len */
    ProductionService.updateProductionPriceV2(
      this.activeSession.id,
      dataToSend,
    ).then( ( response ) => {
      if ( !response.ok ) cb( null, response.errors ? response.errors : 'ERROR' );
      else cb( null, 'DataSaved' );
    } );
  };
  sendDataBundles = ( bundles, cb ) => {
    const priceStatus = [];
    _.map( bundles, ( bundleX ) => {
      const data = {
        bundle: bundleX.id,
        active: bundleX.active,
        price: bundleX.monthlyFee,
        tradeSalePrice: bundleX.tradeSalePrice,
        monthlyFee: bundleX.monthlyFee,
        monthlyTradeSaleFee: bundleX.tradeSalePrice,
      };
      priceStatus.push( data );
    } );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      priceStatus,
    };
    BundleService.updateBundlesV2( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        if ( !response.ok ) cb( null, response.errors ? response.errors : 'ERROR' );
        else {
          cb( null, 'DataSaved' );
        }
      },
    );
  };

  sendDataServices = ( services, cb ) => {
    const priceStatus = [];
    _.map( services, ( serviceX ) => {
      const data = {
        service: serviceX.id,
        active: serviceX.active,
        price: serviceX.price,
        tradeSalePrice: serviceX.tradeSalePrice,
      };
      priceStatus.push( data );
    } );

    const dataToSend = {
      workgroup: this.license.workGroup.id,
      priceStatus,
    };
    ServiceService.updateServicesV2( this.activeSession.id, dataToSend ).then(
      ( response ) => {
        if ( !response.ok ) {
          cb( null, response.errors ? response.errors : 'ERROR' );
        } else cb( null, 'DataSaved' );
      },
    );
  };

  toggleConfirmSaveModal = () => {
    const { confirmSaveModal } = this.state;
    this.setState( { confirmSaveModal: !confirmSaveModal } );
  };

  confirmPorcent = ( priceProduct, tradeSalePrice, field ) => {
    // eslint-disable-next-line max-len
    if (
      _.isNaN( priceProduct, tradeSalePrice )
      || _.isNaN( tradeSalePrice )
      || tradeSalePrice === 0
      || priceProduct === 0
      || priceProduct === '0'
    ) return null;
    // eslint-disable-next-line max-len
    const porcMargin = ( ( Number( priceProduct ) - Number( tradeSalePrice ) ) / Number( priceProduct ) )
      * 100;
    this.confirmLessFivePercentProduct[field] = porcMargin < 5 ? 1 : 0;
    /* eslint-disable-next-line max-len */
    this.boolConfirmLessFivePercentProduct = _.sum( _.values( this.confirmLessFivePercentProduct ) ) === 0;
    return null;
  };
  calPorcMargin = ( price, unitCost ) => {
    /* eslint-disable-next-line max-len */
    if (
      !price
      || !unitCost
      || _.isNaN( price )
      || _.isNaN( unitCost )
      || unitCost === 0
    ) return 0;
    const porcMargin = ( ( price - unitCost ) * 100 ) / price;

    return porcMargin;
  };

  render() {
    const {
      categories,
      confirmSaveModal,
      confirmExitModal,
      bundles,
      services,
    } = this.state;
    const { user } = this.props;
    if ( user.activeSession.distributionType === 'studio' ) {
      this.mkmode = { ...user.activeSession.studioFields };
    } else {
      this.mkmode = { ...user.activeSession.enterpriseFields };
    }
    return (
      <>
        <HeadConfig
          breadcrumbsLinks={[
            {
              url: '/',
              name: 'HomeLink',
            },
            { name: 'DecisionDashboard-prices' },
          ]}
        />

        <Form
          initialValues={{ categories, bundles, services }}
          onSubmit={this.submitForm}
          mutators={{ ...arrayMutators }}
          validate={() => {
            const errors = {};
            return errors;
          }}
          render={( { handleSubmit, dirty } ) => (
            <form onSubmit={handleSubmit}>
              <Prompt
                when={!this.canExit}
                message={( location ) => {
                  const { canExit } = this;
                  if ( dirty && !canExit ) {
                    this.goTo = location.pathname;
                    this.toggleConfirmExitModal();
                    return false;
                  }
                  return true;
                }}
              />
              <KotlerSectionHeader
                title="prices"
                image={HeaderImage}
                titleSubmit="save"
                titleCancel="cancelBtn"
                cancelTo="/"
                onSubmit={() => this.toggleConfirmSaveModal()}
              />
              <SectionInfoText title="help.price.snackbar" />
              <FieldArray name="categories">
                {data => data.fields.map( ( itemField, itemIndex ) => {
                  const item = data.fields.value[itemIndex];

                  return (
                    <Grid
                      container
                      className="kotler-table-container"
                      wrap="nowrap"
                      key={item.id}
                    >
                      <Grid item>
                        <KotlerProductIcon
                          minHeight="123px"
                          img={item.name === 'Laptops' ? 'Laptop' : item.name}
                        />
                      </Grid>
                      <Grid item className="flex-grow-1">
                        <Grid container key={item.id}>
                          <Grid item xs={12}>
                            <KotlerTableTitle
                              disableIcon
                              titleClassName="ml-3"
                              category={item}
                              title={
                                item.name === 'Laptops' ? 'Laptop' : item.name
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Table size="small" className="kotler-table">
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ width: '12%' }}>
                                    <IntlMessages id="model" />
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ width: '8%' }}
                                  >
                                    <HoverTitle id="product.unitCostValue.hover">
                                      <span>
                                        <IntlMessages id="unitCost" />
                                      </span>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell
                                    align="center"
                                    style={{ width: '200px' }}
                                  >
                                    <HoverTitle id="product.priceValue.hover">
                                      <span>
                                        <IntlMessages id="price" />
                                      </span>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell align="right">
                                    <HoverTitle id="marginIn$CompanySaleProductConsumer">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="Sales margin" />
                                        {' '}
                                        <span>($)</span>
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell align="right">
                                    <HoverTitle id="marginIn%CompanySaleProductConsumer">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="Sales margin" />
                                        {' '}
                                        <span>(%)</span>
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell className="separator" />
                                  <TableCell
                                    align="center"
                                    style={{ width: 150 }}
                                  >
                                    <HoverTitle id="priceRetailerProduct">
                                      <span>
                                        <IntlMessages id="retailerPrice" />
                                      </span>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell align="right">
                                    <HoverTitle id="marginIn$CompanySaleProductRetailerEstablishedPrice">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="margin" />
                                        {' '}
                                        <span>($)</span>
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                  <TableCell align="right">
                                    <HoverTitle id="marginIn%CompanySaleProductRetailerEstablishedPrice">
                                      <div className="d-inline-block ">
                                        <IntlMessages id="margin" />
                                        {' '}
                                        <span>(%)</span>
                                      </div>
                                    </HoverTitle>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <FieldArray name={`${itemField}.products`}>
                                  {/* eslint-disable-next-line max-len */}
                                  {productsData => productsData.fields.map(
                                    ( productField, productIndex ) => {
                                      const product = categories[itemIndex].products[
                                        productIndex
                                      ];
                                      if ( !product ) return null;
                                      return [
                                        <TableRow key={product.id}>
                                          <TableCell>
                                            {product.name}
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="product.unitCostValue.hover">
                                              <span>
                                                {Util.formatCurrency(
                                                  product.unitCost,
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="center">
                                            <div>
                                              <HoverTitle id="product.priceValue.hover">
                                                <TextField
                                                  containerClass="kt-field-light-gray"
                                                  field={`${productField}.price`}
                                                  marginContainer={false}
                                                  type="number"
                                                  allowZero
                                                  disabled={
                                                    user
                                                        && user.activeSession
                                                        && user.activeSession
                                                          .status
                                                          !== STATUS.READY
                                                  }
                                                  inputProps={{ className: 'text-right' }}
                                                  validate={composeValidators(
                                                    required,
                                                    greaterOrEqualValue(
                                                      Number(
                                                        _.get(
                                                          productsData.fields,
                                                          `value[${productIndex}].unitCost`,
                                                          0,
                                                        ),
                                                      ) * 0.5,
                                                    ),
                                                    lessOrEqualThanValue(
                                                      Number(
                                                        _.get(
                                                          productsData.fields,
                                                          `value[${productIndex}].unitCost`,
                                                          0,
                                                        ),
                                                      ) * 10,
                                                    ),
                                                  )}
                                                  translateValues={{
                                                    value:
                                                          _.get(
                                                            productsData.fields,
                                                            `value[${productIndex}].unitCost`,
                                                            0,
                                                          ) * 0.5,
                                                    value2:
                                                          _.get(
                                                            productsData.fields,
                                                            `value[${productIndex}].unitCost`,
                                                            0,
                                                          ) * 10,
                                                  }}
                                                  onChange={( value ) => {
                                                    const previousValue = _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].price`,
                                                    );
                                                    if (
                                                      previousValue
                                                          !== value
                                                    ) {
                                                      this.setChangeStatus();
                                                    }
                                                  }}
                                                />
                                              </HoverTitle>
                                              {this.confirmPorcent(
                                                _.get(
                                                  productsData.fields,
                                                  `value[${productIndex}].price`,
                                                ),
                                                _.get(
                                                  productsData.fields,
                                                  `value[${productIndex}].tradeSalePrice`,
                                                ),
                                                product.name,
                                              )}
                                            </div>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="marginIn$CompanySaleProductConsumer">
                                              <span>
                                                {Util.formatCurrency(
                                                  _.get(
                                                    productsData.fields,
                                                    `value[${productIndex}].price`,
                                                    0,
                                                  ) - product.unitCost,
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="marginIn%CompanySaleProductConsumer">
                                              <span>
                                                {_.get(
                                                  productsData.fields,
                                                  `value[${productIndex}].price`,
                                                  0,
                                                ) === '0'
                                                    || _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].price`,
                                                      0,
                                                    ) === 0
                                                  ? 0
                                                  : Util.formatNumberNoDecimals(
                                                    ( ( _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].price`,
                                                      0,
                                                    )
                                                            - product.unitCost )
                                                            / _.get(
                                                              productsData.fields,
                                                              `value[${productIndex}].price`,
                                                              0,
                                                            ) )
                                                            * 100,
                                                  )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell className="separator" />
                                          <TableCell align="center">
                                            <HoverTitle id="priceRetailerProduct">
                                              <div style={{ width: 120 }}>
                                                <TextField
                                                  field={`${productField}.tradeSalePrice`}
                                                  marginContainer={false}
                                                  type="number"
                                                  allowZero
                                                  disabled={
                                                    user
                                                        && user.activeSession
                                                        && user.activeSession
                                                          .status
                                                          !== STATUS.READY
                                                  }
                                                  inputProps={{ className: 'text-right' }}
                                                  validate={composeValidators(
                                                    required,
                                                    greaterOrEqualThanMultipliedBy(
                                                      `categories[${itemIndex}].products[${productIndex}]price`,
                                                      0.5,
                                                    ),
                                                    lessOrEqualThan(
                                                      `categories[${itemIndex}].products[${productIndex}]price`,
                                                    ),
                                                  )}
                                                  translateValues={{
                                                    greaterOrEqualThanMultiplied:
                                                          _.get(
                                                            productsData.fields,
                                                            `value[${productIndex}].price`,
                                                            0,
                                                          ) * 0.5,
                                                    label: _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].price`,
                                                      0,
                                                    ),
                                                  }}
                                                  onChange={( value ) => {
                                                    const previousValue = _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].tradeSalePrice`,
                                                    );
                                                    if (
                                                      previousValue
                                                          !== value
                                                    ) {
                                                      this.setChangeStatus();
                                                    }
                                                  }}
                                                />
                                              </div>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="marginIn$CompanySaleProductRetailerEstablishedPrice">
                                              <span>
                                                {Util.formatCurrency(
                                                  _.get(
                                                    productsData.fields,
                                                    `value[${productIndex}].tradeSalePrice`,
                                                    0,
                                                  )
                                                        - _.get(
                                                          productsData.fields,
                                                          `value[${productIndex}].unitCost`,
                                                          0,
                                                        ),
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                          <TableCell align="right">
                                            <HoverTitle id="marginIn%CompanySaleProductRetailerEstablishedPrice">
                                              <span>
                                                {Util.formatNumberNoDecimals(
                                                  this.calPorcMargin(
                                                    _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].tradeSalePrice`,
                                                      0,
                                                    ),
                                                    _.get(
                                                      productsData.fields,
                                                      `value[${productIndex}].unitCost`,
                                                      0,
                                                    ),
                                                    product.name,
                                                  ),
                                                )}
                                              </span>
                                            </HoverTitle>
                                          </TableCell>
                                        </TableRow>,
                                      ];
                                    },
                                  )
                                  }
                                </FieldArray>
                              </TableBody>
                            </Table>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                } )
                }
              </FieldArray>
              {this.mkmode.decision.price.tablet && (
                <Fragment>
                  <TableFormPrice
                    name="services"
                    title="services"
                    icon="Service"
                    entitiesArray={services}
                    user={user}
                    principalHeadMessage="service"
                    calPorcentFunction={this.calPorcMargin}
                    changeStatusFunction={this.setChangeStatus}
                    avatarSrc={service}
                  />
                  <TableFormPrice
                    name="bundles"
                    title="bundles"
                    icon="Bundle"
                    entitiesArray={bundles}
                    user={user}
                    principalHeadMessage="bundle"
                    calPorcentFunction={this.calPorcMargin}
                    changeStatusFunction={this.setChangeStatus}
                    avatarSrc={bundle}
                  />
                  <div className="w-100 mb-5" />
                </Fragment>
              )}
              <ConfirmCancelButtons
                titleSubmit="save"
                titleCancel="cancelBtn"
                cancelTo="/"
                onSubmit={() => this.toggleConfirmSaveModal()}
              />
              <ConfirmationDialog
                open={confirmSaveModal}
                title={
                  this.boolConfirmLessFivePercentProduct
                    ? 'confirmSaveProductProduction'
                    : 'confirmSaveProductAlertWithLessFivePercent'
                }
                showIcon
                confirmBtnText="save"
                closeBtnText="cancelBtn"
                type="save"
                onSubmit={() => {
                  this.toggleConfirmSaveModal();
                  handleSubmit();
                }}
                onClose={this.toggleConfirmSaveModal}
              />
              <ConfirmationDialog
                open={confirmExitModal}
                title="confirmExitWithOutSavingProducts"
                subTitle="subtitleExitWithoutSaving"
                confirmBtnText="yesModalBtn"
                closeBtnText="cancelBtn"
                onSubmit={() => {
                  this.toggleConfirmExitModal();
                  if ( !this.isEscapeKey ) {
                    this.setConfirmExitModal();
                  }
                  this.isEscapeKey = false;
                }}
                onClose={this.toggleConfirmExitModal}
              />
            </form>
          )}
        />
      </>
    );
  }
}
const TableFormPrice = ( {
  name,
  entitiesArray,
  principalHeadMessage,
  user,
  calPorcentFunction,
  changeStatusFunction,
  title,
  icon,
} ) => (
  <Grid container wrap="nowrap" className="kotler-table-container">
    <Grid item>
      <KotlerProductIcon minHeight="123px" img={icon} />
    </Grid>
    <Grid item className="flex-grow-1">
      <Grid container>
        <Grid item xs={12}>
          <KotlerTableTitle
            disableIcon
            titleClassName="ml-3"
            icon={icon}
            title={title}
          />
        </Grid>

        <Grid item xs={12}>
          <Table size="small" className="kotler-table">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '12%' }}>
                  <IntlMessages id={principalHeadMessage} />
                </TableCell>
                <TableCell align="right" style={{ width: '8%' }}>
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'monthlyCostSubscriptionSoldService'
                        : 'totalCostBundleCompany'
                    }
                  >
                    <span>
                      <IntlMessages id="unitCost" />
                    </span>
                  </HoverTitle>
                </TableCell>
                <TableCell align="center" style={{ width: '200px' }}>
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'monthlySubscriptionCompanySellsServicePublic'
                        : 'monthlyBundleCostBuyers'
                    }
                  >
                    <span>
                      <IntlMessages id="monthlyFee" />
                    </span>
                  </HoverTitle>
                </TableCell>
                <TableCell align="right">
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'marginIn$CompanySaleServiceConsumer'
                        : 'marginIn$CompanySaleBundleConsumer'
                    }
                  >
                    <div className="d-inline-block ">
                      <IntlMessages id="Sales margin" />
                      {' '}
                      <span>($)</span>
                    </div>
                  </HoverTitle>
                </TableCell>
                <TableCell align="right">
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'marginIn%CompanySaleServiceConsumer'
                        : 'marginIn%CompanySaleBundleConsumer'
                    }
                  >
                    <div className="d-inline-block ">
                      <IntlMessages id="Sales margin" />
                      {' '}
                      <span>(%)</span>
                    </div>
                  </HoverTitle>
                </TableCell>
                <TableCell className="separator" />
                <TableCell align="right" style={{ width: 150 }}>
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'monthlySubscriptionCompanySellsServiceRetailer'
                        : 'monthlyBundleCostRetailer'
                    }
                  >
                    <span>
                      <IntlMessages id="retailerPrice" />
                    </span>
                  </HoverTitle>
                </TableCell>
                <TableCell align="right">
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'marginIn$RetailerSaleService'
                        : 'monthlyMarginIn$RetailerSaleBundle'
                    }
                  >
                    <div className="d-inline-block ">
                      <IntlMessages id="margin" />
                      {' '}
                      <span>($)</span>
                    </div>
                  </HoverTitle>
                </TableCell>
                <TableCell align="right">
                  <HoverTitle
                    id={
                      name !== 'bundles'
                        ? 'marginIn%RetailerSaleService'
                        : 'marginIn%RetailerSaleBundle'
                    }
                  >
                    <div className="d-inline-block ">
                      <IntlMessages id="margin" />
                      {' '}
                      <span>(%)</span>
                    </div>
                  </HoverTitle>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <FieldArray name={name}>
                {( { fields } ) => fields.map( ( entityField, entityIndex ) => {
                  const entityX = entitiesArray[entityIndex];
                  if ( !entityX ) return null;
                  return [
                    <TableRow key={entityX.id}>
                      <TableCell>{entityX.name}</TableCell>

                      <TableCell align="right">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'monthlyCostSubscriptionSoldService'
                              : 'totalCostBundleCompany'
                          }
                        >
                          <span>{Util.formatCurrency( entityX.unitCost )}</span>
                        </HoverTitle>
                      </TableCell>
                      <TableCell align="center">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'monthlySubscriptionCompanySellsServicePublic'
                              : 'monthlyBundleCostBuyers'
                          }
                        >
                          <div>
                            <TextField
                              containerClass="kt-field-light-gray"
                              field={
                                name !== 'bundles'
                                  ? `${entityField}.price`
                                  : `${entityField}.monthlyFee`
                              }
                              marginContainer={false}
                              type="number"
                              allowZero
                              disabled={
                                user
                                  && user.activeSession
                                  && user.activeSession.status !== STATUS.READY
                              }
                              inputProps={{ className: 'text-right' }}
                              validate={
                                name !== 'bundles'
                                  ? composeValidators(
                                    required,
                                    greaterOrEqualValue( 0 ),
                                    lessOrEqualThanValue(
                                      parseInt( entityX.unitCost * 10, 10 ),
                                    ),
                                  )
                                  : composeValidators(
                                    required,
                                    greaterOrEqualValue( 0 ),
                                    lessOrEqualThanValue(
                                      parseInt(
                                        ( ( _.get(
                                          fields,
                                          `value[${entityIndex}].unitCost`,
                                        )
                                              * 5 )
                                              / ( 1
                                                + _.get(
                                                  fields,
                                                  `value[${entityIndex}].totalFee`,
                                                ) ) )
                                              * 100,
                                        10,
                                      ) / 100,
                                    ),
                                    greaterOrEqualThanValue(
                                      ( _.get(
                                        fields,
                                        `value[${entityIndex}].unitCost`,
                                      )
                                            / ( _.get(
                                              fields,
                                              `value[${entityIndex}].totalFee`,
                                            )
                                              + 1 ) )
                                            * 0.5,
                                    ),
                                  )
                              }
                              translateValues={
                                name !== 'bundles'
                                  ? {
                                    value:
                                          _.get(
                                            fields,
                                            `value[${entityIndex}].unitCost`,
                                          ) * 0.5,
                                    value2:
                                          _.get(
                                            fields,
                                            `value[${entityIndex}].unitCost`,
                                          ) * 10,
                                  }
                                  : {
                                    value: 0,
                                    value2:
                                          parseInt(
                                            ( ( _.get(
                                              fields,
                                              `value[${entityIndex}].unitCost`,
                                            )
                                              * 5 )
                                              / ( 1
                                                + _.get(
                                                  fields,
                                                  `value[${entityIndex}].totalFee`,
                                                ) ) )
                                              * 100,
                                            10,
                                          ) / 100,
                                    value3:
                                          parseInt(
                                            ( _.get(
                                              fields,
                                              `value[${entityIndex}].unitCost`,
                                            )
                                              / ( _.get(
                                                fields,
                                                `value[${entityIndex}].totalFee`,
                                              )
                                                + 1 ) )
                                              * 0.5
                                              * 100,
                                            10,
                                          ) / 100,
                                  }
                              }
                              onChange={( value ) => {
                                const previousValue = _.get(
                                  fields,
                                  `value[${entityIndex}].price`,
                                );
                                if ( previousValue !== value ) {
                                  changeStatusFunction();
                                }
                              }}
                            />
                          </div>
                        </HoverTitle>
                      </TableCell>
                      <TableCell align="right">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'marginIn$CompanySaleServiceConsumer'
                              : 'marginIn$CompanySaleBundleConsumer'
                          }
                        >
                          <span>
                            {name !== 'bundles'
                              ? Util.formatCurrency(
                                _.get(
                                  fields,
                                  `value[${entityIndex}].price`,
                                  0,
                                ) - entityX.unitCost,
                              )
                              : Util.formatCurrency(
                                _.get(
                                  fields,
                                  `value[${entityIndex}].monthlyFee`,
                                  0,
                                )
                                      * ( 1 + entityX.totalFee )
                                      - entityX.unitCost,
                              )}
                          </span>
                        </HoverTitle>
                      </TableCell>
                      <TableCell align="right">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'marginIn%CompanySaleServiceConsumer'
                              : 'marginIn%CompanySaleBundleConsumer'
                          }
                        >
                          <span>
                            {name !== 'bundles'
                              ? _.get(
                                fields,
                                `value[${entityIndex}].price`,
                                0,
                              ) === '0'
                                ? 0
                                : Util.formatNumberNoDecimals(
                                  ( ( _.get(
                                    fields,
                                    `value[${entityIndex}].price`,
                                    0,
                                  )
                                        - entityX.unitCost )
                                        / _.get(
                                          fields,
                                          `value[${entityIndex}].price`,
                                          0,
                                        ) )
                                        * 100,
                                )
                              : _.get(
                                fields,
                                `value[${entityIndex}].monthlyFee`,
                                0,
                              ) === '0'
                                ? 0
                                : Util.formatNumberNoDecimals(
                                  calPorcentFunction(
                                    _.get(
                                      fields,
                                      `value[${entityIndex}].monthlyFee`,
                                      0,
                                    )
                                        * ( 1 + entityX.totalFee ),
                                    entityX.unitCost,
                                  ),
                                )}
                          </span>
                        </HoverTitle>
                      </TableCell>
                      <TableCell className="separator" />
                      <TableCell align="center">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'monthlySubscriptionCompanySellsServiceRetailer'
                              : 'monthlyBundleCostRetailer'
                          }
                        >
                          <div>
                            <TextField
                              field={`${entityField}.tradeSalePrice`}
                              marginContainer={false}
                              type="number"
                              allowZero
                              disabled={
                                user
                                  && user.activeSession
                                  && user.activeSession.status !== STATUS.READY
                              }
                              inputProps={{ className: 'text-right' }}
                              validate={composeValidators(
                                required,
                                greaterOrEqualThanMultipliedBy(
                                  name !== 'bundles'
                                    ? `services[${entityIndex}].price`
                                    : `bundles[${entityIndex}].monthlyFee`,
                                  0.5,
                                ),

                                /*  greaterOrEqualValue(
                                    _.get(
                                      fields,
                                      name !== 'bundles'
                                        ? `value[${entityIndex}].price`
                                        : `value[${entityIndex}].monthlyFee`,
                                    ) * 0.5,
                                  ), */
                                lessOrEqualThan(
                                  name !== 'bundles'
                                    ? `services[${entityIndex}].price`
                                    : `bundles[${entityIndex}].monthlyFee`,
                                ),
                              )}
                              translateValues={
                                name !== 'bundles'
                                  ? {
                                    greaterOrEqualThanMultiplied:
                                          _.get(
                                            fields,
                                            `value[${entityIndex}].price`,
                                          ) * 0.5,
                                    label: _.get(
                                      fields,
                                      `value[${entityIndex}].price`,
                                    ),
                                  }
                                  : {
                                    greaterOrEqualThanMultiplied:
                                          _.get(
                                            fields,
                                            `value[${entityIndex}].monthlyFee`,
                                          ) * 0.5,
                                    label: _.get(
                                      fields,
                                      `value[${entityIndex}].monthlyFee`,
                                    ),
                                  }
                              }
                              onChange={( value ) => {
                                const previousValue = _.get(
                                  fields,
                                  `value[${entityIndex}].tradeSalePrice`,
                                );
                                if ( previousValue !== value ) {
                                  changeStatusFunction();
                                }
                              }}
                            />
                          </div>
                        </HoverTitle>
                      </TableCell>
                      <TableCell align="right">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'marginIn$RetailerSaleService'
                              : 'monthlyMarginIn$RetailerSaleBundle'
                          }
                        >
                          <span>
                            {name !== 'bundles'
                              ? Util.formatCurrency(
                                _.get(
                                  fields,
                                  `value[${entityIndex}].tradeSalePrice`,
                                  0,
                                )
                                      - _.get(
                                        fields,
                                        `value[${entityIndex}].unitCost`,
                                        0,
                                      ),
                              )
                              : Util.formatCurrency(
                                _.get(
                                  fields,
                                  `value[${entityIndex}].tradeSalePrice`,
                                  0,
                                )
                                      * ( 1 + entityX.totalFee )
                                      - entityX.unitCost,
                                0,
                              )}
                          </span>
                        </HoverTitle>
                      </TableCell>
                      <TableCell align="right">
                        <HoverTitle
                          id={
                            name !== 'bundles'
                              ? 'marginIn%RetailerSaleService'
                              : 'marginIn%RetailerSaleBundle'
                          }
                        >
                          <span>
                            {name !== 'bundles'
                              ? Util.formatNumberNoDecimals(
                                calPorcentFunction(
                                  _.get(
                                    fields,
                                    `value[${entityIndex}].tradeSalePrice`,
                                    0,
                                  ),
                                  _.get(
                                    fields,
                                    `value[${entityIndex}].unitCost`,
                                    0,
                                  ),
                                ),
                              )
                              : Util.formatNumberNoDecimals(
                                calPorcentFunction(
                                  _.get(
                                    fields,
                                    `value[${entityIndex}].tradeSalePrice`,
                                    0,
                                  )
                                        * ( 1 + entityX.totalFee ),
                                  entityX.unitCost,
                                ),
                              )}
                          </span>
                        </HoverTitle>
                      </TableCell>
                    </TableRow>,
                  ];
                } )
                }
              </FieldArray>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
const mapStateToProps = ( { user } ) => ( { user } );

const mapDispatchToProps = {
  toggleAlert: SettingsActions.toggleAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)( injectIntl( Products ) );
